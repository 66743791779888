import * as React from "react";
import { Container } from "react-bootstrap";
import { PageProps, graphql } from "gatsby";
import Activation from "../../components/activation";
import Feature from "../../components/Feature";
import FeaturesSummary from "../../components/productPage/featuresSummary";
import Layout from "../../components/layout";
import { container } from "./inspections-and-rounds.module.css";
import { SEO } from "../../components/seo";

const InspectionsAndRoundsPage = ({
  data,
}: PageProps<Queries.inspaectionsAndRoundsQuery>) => {
  return (
    <Layout pageTitle="Guest Experience App">
      <Container fluid className={container}>
        <Feature
          title="Streamlined Processes for Inspections and Rounds"
          subtitle="Elevate efficiency of your inspections and rounds with Floatist's streamlined solution. Our platform simplifies the process of conducting detailed inspections by providing customizable checklists and real-time data capture, ensuring nothing is overlooked. With Floatist, you can quickly record findings, attach photos, and generate reports, all from a mobile device, enhancing both accuracy and accountability. "
          // imageData={data.file.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Custom checklists"
          subtitle="Maximize the efficiency of your inspections and rounds with Floatist's custom checklists feature. Tailor checklists to meet specific needs, ensuring thorough and consistent assessments while streamlining the inspection process for your crew."
          // imageData={data.file.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title=""
          subtitle=""
          // imageData={data.file.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Easily update component hours"
          subtitle="Component hours can be updated during the completion of an inspection round, directly from the checklist"
          //imageData={data.file.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title=""
          subtitle=""
          // imageData={data.file.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Train new or interim staff using custom instructions"
          subtitle="Simplify the training of new or interim staff with Floatist's customizable checklists, complete with images, videos, and step-by-step instructions. This feature makes it easy for newcomers to perform checks accurately and confidently, ensuring consistent quality and efficiency, even if they've never done it before."
          // imageData={data.file.childImageSharp.gatsbyImageData}
          textSide="left"
        />
      </Container>

      <FeaturesSummary />
      <Activation />
    </Layout>
  );
};

export default InspectionsAndRoundsPage;

export const Head = () => (
  <SEO
    description="Learn how to effectively manage ISM using Floatist's
    Inspections and Rounds feature"
  />
);

export const query = graphql`
  query inspaectionsAndRounds {
    file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 400)
      }
    }
  }
`;
